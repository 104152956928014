import React from "react";

const ContentOne = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        {/* Section title */}
        <div className="row align-items-xl-center justify-content-between">
          <div
            className="col-xl-7 col-lg-6 col-md-10"
            data-aos="fade-right"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <h1 className="font-size-10 mb-2 letter-spacing-n83">
              Become a Digma Partner and let's work together
            </h1>
          </div>
          <div
            className="col-xl-5 col-lg-6 col-md-8"
            data-aos="fade-left"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <h2 className="font-size-7 mb-0 pr-xl-2 pr-lg-5 pr-md-10 pr-sm-20 mt-7 mt-lg-0">
              White Label Digma's Saas Software Services. For resellers and agencies.
            </h2>
          </div>
        </div>
        {/* End Section title */}
        {/* Services */}
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20"
              data-aos="fade-up"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="square-60 bg-form rounded-10 text-white font-size-7">
                <i className="fa fa-tag" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">White label</h4>
                <p className="font-size-5 mb-0">
                  Digma’s name does not show up anywhere on the product – it is all yours as far as customers are concerned.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <div className="square-60 bg-image rounded-10 text-white font-size-7">
                <i className="fa fa-arrows-alt" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Resellers</h4>
                <p className="font-size-5 mb-0">
                  Depending on experience and enthusiasm for our product rates can vary, starting at 10%. 
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20"
              data-aos="fade-up"
              data-aos-delay={900}
              data-aos-once="true"
            >
              <div className="square-60 bg-link rounded-10 text-white font-size-7">
                <i className="fa fa-users" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Agencies</h4>
                <p className="font-size-5 mb-0">
                  We will set you up with an account and you can set up your own prices.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row justify-content-center mb-20" data-aos="fade-up"
              data-aos-delay={500}
              data-aos-once="true">
            <div className="mt-20 col-lg-6 col-md-6 col-xs-8">
              <h3 className="mb-7">Let's have a chat</h3>
              <p>Fill in the form and we will be in contact wih you as soon as possible to discuss options.</p>
              <p>Look forward to hearing from you,</p>
              <p className="">The Digma Team.</p>
            </div>
            <div className="mt-20 col-lg-6 col-md-6 col-xs-8">
              <form
                        name="contact"
                        method="POST"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        data-aos="fade-up"
                        data-aos-duration={1100}
                        data-aos-once="true"
                      >
                        <input type="hidden" name="form-name" value="contact" />
                        {/* Company Name */}
                        <div className="form-group mb-7 position-relative">
                          <input
                            type="text"
                            name="name"
                            className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                            placeholder="Your Name"
                            id="company"
                            required
                          />
                        </div>
                        {/* Email */}
                        <div className="form-group mb-7 position-relative">
                          <input
                            type="email"
                            name="email"
                            className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                            placeholder="Email Address"
                            id="email"
                            required
                          />
                        </div>
                        {/* Email */}
                        <div className="form-group mb-7 position-relative">
                          <input
                            type="number"
                            name="tel"
                            className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                            placeholder="Contact Number"
                            id="tel"
                            required
                          />
                        </div>                        
                        {/* Company Name */}
                        <div className="form-group mb-7 position-relative">
                          <textarea
                            name="message"
                            id="message"
                            placeholder="When is a good time to contact you?"
                            className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 pt-7 font-size-5 border-default-color"
                            defaultValue={""}
                            required
                          />
                        </div>
                        <div className="button">
                          <button
                            type="submit"
                            href="/#"
                            className="btn btn-digma h-55 w-100 rounded-4"
                          >
                            Send
                          </button>
                        </div>
                  </form>      
            </div>          
        </div>
      </div>
    </div>
  );
};

export default ContentOne;
