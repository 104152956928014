import React from "react";
import PageWrapper from "../../components/PageWrapper";
import ContentOne from "../../sections/partners/ContentOne";
import SEO from '../../components/SEO';
import metaImg from '../../assets/image/metaimg/partners.jpg';
import Commit from "../../sections/common/Commit";

const FeaturesPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          footerStyle: "digma",
        }}>
        <SEO
          title="Become a Digma Partner"
          description="White Label Digma's Saas Software Services. For resellers and agencies."
          image={metaImg}
        />            
        
        <ContentOne className="pt-25" />
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>           
      </PageWrapper>
    </>
  );
};
export default FeaturesPage;
